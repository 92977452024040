<template>
  <div class="tickets hide-scrollbar" :class="[$mq]">
    <!-- <div v-if="$mq.includes('desktop')" class="andyimg"></div> -->
    <div class="boxes hide-scrollbar">
      <div class="box" @click="openMail">
        <img src="/img/box-arrow.svg" alt="box-arrow">
        <div class="label">ETIQUETAS</div>
      </div>
      <div class="box" @click="openMail">
        <img src="/img/box-arrow.svg" alt="box-arrow">
        <div class="label">TRAZABILIDAD</div>
      </div>

      <div class="products-state">
        <div class="box" @click="openMail">
          <div class="count-active">
            <img src="/img/timer.svg" alt="box-arrow">
            <label>14</label>
          </div>
          <div class="label">
            Timers
            <br>
            activos
          </div>
        </div>
        <div class="box" @click="openMail">
          <div class="products-discard">
            <label>06</label>
          </div>
          <div class="label">
            Productos a
            <br>
            desechar
          </div>
        </div>
        <div class="box" @click="openMail">
          <div class="products-discard">
            <label>06</label>
          </div>
          <div class="label">
            Productos por
            <br>
            producir a las
            <br>
            12:00
          </div>
        </div>
        <div class="box" @click="openMail">
          <div class="count-active">
            <img src="/img/check-circle.svg" alt="box-arrow">
            <label>18</label>
          </div>
          <div class="label">
            Productos en
            <br>
            buen estado
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'Tickets',
    destroyed() {
        this.$bar.reset()
    }
}
</script>

<style lang="scss">
.zsiq_floatmain {
    display: none !important;
}

.tickets {
    @include display-flex();
    @include align-items(center);
    width: 100%;
    height: 100%;
    overflow: auto;

    .boxes {
        @include display-flex();
        @include justify-content(flex-start);
        @include align-content(flex-start);
        @include flex-wrap(wrap);
        padding: 60px;
        width: 100%;
        height: 100%;

        .box {
            @include display-flex();
            @include justify-content(center);
            @include align-items();
            @include border-radius(5px);
            @include interaction();
            background-color: #fff;
            padding: 14px 20px;
            width: 300px;
            margin: 20px;

            .label {
              @include display-flex();
              @include justify-content(center);
              @include align-items(center);
              @include font-size(sm);
              font-family: $text-bold;
              color: $neutro-s80;
              padding-left: 20px;
            }

            img {
              width: 25px;
              height: 25px;
            }

            .count-active {
              display: flex;
              @include align-items(center);

              label {
                font-size: 21px;
                margin: 0px 0px;
                color: #157D82;
                font-weight: 600;
              }
            }

            .products-discard {
              border: 6px solid #BD1F1E;
              border-radius: 50%;

              label {
                margin: 0px;
                font-size: 20px;
                padding: 21px 20px;
                font-weight: 600;
                color: #222222;
              }
            }

            &.helpcenter {
              .icon {
                @include background($image: img('book.svg'), $position: center center, $size: 38px);
              }
            }
        }

        .products-state {
          display: flex;
        }
    }
}
</style>

// LANDSCAPE STYLES
<style lang="scss" scoped>
.tickets.landscape {
    .boxes {
        width: 100%;
    }
}
</style>
// PORTRAIT STYLES
<style lang="scss" scoped>

</style>
